@import "src/styles/variables";

.admin-panel-billing-current-period {
    &-content {
        margin-top: 157px;
    }

    .admin-panel-container-text-table table tr.admin-panel-billing-current-period--last-row td {
        padding-top: 24px;
    }
}

@import "src/styles/variables";

.wed-media-gallery-preview-menu {
    top: 0;
    position: absolute;
    z-index: 10;
    background: transparent;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    right: 0;

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.8);
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
    }

    &__content {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
    }

    &:hover {
        .wed-media-gallery-preview-menu__background {
            opacity: 1;
        }
    }

    &__left {
        width: 50%;
        height: 40px;
        display: flex;
        align-items: center;
    }

    &__right {
        width: 50%;
        height: 40px;
        display: flex;
        align-items: center;
    }

    &__back-to-files {
        margin-left: 6px;
        flex-direction: row;
        align-items: center;
    }

    &__back-to-files-label {
        display: flex;
        align-items: center;
        color: $defaultTextColor;
    }

    &__back-to-files:hover {
        .wed-media-gallery-preview-menu__back-to-files-label {
            color: $iconControlHoverColor;
        }
    }
}

.wed-media-gallery {
    .wed-media-gallery-preview-menu {
        .wed-media-gallery-preview-menu__left {
            .wed-media-gallery-preview-menu__back-to-files {
                width: auto;
                display: flex;
            }
        }
    }
}

.wed-media-gallery {
    .wed-media-gallery-preview-menu__right {
        .wed-icon-button {
            margin-left: 10px;
        }

        .wed-icon-button:first-child {
            margin-left: auto;
        }
    }
}

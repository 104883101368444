@import "src/styles/variables";

body .not-logged-popup-page {
    border-radius: $notLoggedPageBorderRadius;
    background-color: $notLoggedPageBackgroundColor;
    padding: 48px 40px 48px;
}

.not-logged-popup-page {
    &--header {
        text-align: center;
        margin: 15px 0;

        .MuiTypography-h5 {
            font-size: 30px;
            font-weight: 300;
        }
    }

    &--container {
        display: flex;
    }

    &--with-logo {
        .not-logged-popup-page--content {
            width: calc(100% - 300px);
        }

        .not-logged-popup-page--logo {
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
        }
    }

    &--text-container {
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--text-container-content {
        text-align: center;
    }
}

@import "src/styles/variables";

.project-stat {
    height: 100px;
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    background-color: #191919;
    margin-right: 8px;
    opacity: 0.6;
    border-radius: 3px;

    &__count {
        font-size: 36px;
        line-height: 1;
        position: relative;
        top: -5px;
        text-align: right;

        &--success {
            color: $activeColor;
        }

        &--warning {
            color: $tileDefaultTextColor;
        }

        &--error {
            color: $inactiveColor;
        }
    }

    &__title {
        font-size: 10px;
        color: rgba(250, 250, 250, 0.4);
        letter-spacing: 0.1px;
        line-height: 1.1;
    }
}

.main-menu {
    .project-detail-menu {
        &__comments-proofing {
            a {
                span {
                    width: 190px;
                }
            }
        }
    }
}

@import "src/styles/variables";

.project-tile {
    display: flex;
    flex-direction: column;
    height: #{$tileProjectHeight};

    &__stats {
        display: flex;
    }

    &__title {
        margin-top: 16px;
    }

    &__subtitle {
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: 1.7;
        color: $tileDefaultTextColor;
    }

    &__details {
        display: flex;
        align-items: center;
        margin-top: 16px;
        color: $tileTextDarkColor;
        font-size: 11px;
    }

    &__date,
    &__user {
        color: $tileDefaultTextColor;
        padding: 0 4px;
    }

    &__icons {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;
        grid-gap: 12px;
    }

    .project__favorite {
        position: absolute;
        z-index: 1;
        left: 8px;
        top: 5px;
        transition: opacity 0.3s;
        opacity: 0;
        &--active {
            opacity: 1;
        }
    }

    &:hover {
        .project__favorite {
            opacity: 1;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }
}

#wed-media-gallery-image-transform-component--rect {
    position: absolute;
    width: 100%;
    height: 100%;

    rect {
        fill: white;
        stroke: red;
        stroke-width: 1.25;
        stroke-dasharray: 5, 5;
        stroke-opacity: 1;
        fill-opacity: 0.2;
    }
}

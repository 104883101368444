@import "src/styles/variables";

.sidebar-activities {
    &--date-group {
        margin-top: 10px;
    }

    &--date-group:first-child {
        margin-top: 0;
        .sidebar-activities--date-header {
            border-top: none;
        }
    }

    &--date-header {
        border-top: $sidebarBorderWhite;
        border-bottom: $sidebarBorderWhite;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.25px;
        color: $sidebarActivityHeader;
        padding: 0 38px;
        height: 47px;
        display: flex;
        align-items: center;
    }

    &--date-content {
        display: flex;
        flex-direction: column;
    }

    &--date-activity-item {
        padding: 0 38px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    &--date-activity-item:first-child {
        margin-top: 0;
    }

    &--date-activity-item-created {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.25px;
        color: $sidebarActivityDate;
        height: 30px;
        display: flex;
        align-items: center;
    }

    &--date-activity-item-message {
        display: flex;
        flex-direction: row;
    }

    &--date-activity-item-message-author {
        width: 48px;
    }

    &--date-activity-item-message-body {
        display: flex;
        align-items: center;
        width: calc(100% - 48px);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.3px;
        color: $sidebarDescriptionColor;
    }
}

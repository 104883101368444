@import "src/styles/variables";

.members {
    &-table {
        margin-top: 40px;
        width: 100%;
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);

            &:last-child {
                border-bottom: none;
            }

            td {
                padding-bottom: 10px;
                padding-top: 10px;

                &:first-child {
                    width: 88px;
                    padding-left: 22px;
                }

                &:last-child {
                    width: 68px;
                }
            }
        }
    }

    &-name {
        font-size: 14px;
        font-weight: 300;
        color: #fff;
        margin-top: -1px;
    }

    &-mail {
        font-size: 10px;
        color: $membersEmail;
        font-weight: 300;
        padding-top: 2px;
    }
}

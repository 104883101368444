@import "src/styles/variables";

.summary-page-box-container {
    &--body {
        padding: 38px 60px 70px 60px;
    }

    &--header {
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.24px;
        text-align: left;
        color: $defaultHighlightTextColor;
    }

    &--children {
        padding-top: 34px;
    }
}

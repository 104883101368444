@import "src/styles/variables";

.navbar {
    display: flex;
    align-items: center;

    &__icon {
        cursor: pointer;
        margin-right: 40px;

        svg {
            font-size: 40px;
            fill: rgba(255, 255, 255, 0.2);

            &:hover {
                fill: rgba(255, 255, 255, 0.5);
            }
        }
    }

    &__navigation {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    &__item {
        padding: 5px 40px;
        transition: padding 0.3s;
    }

    &__link {
        color: rgba(250, 250, 250, 0.5);
        text-decoration: none;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0;
        letter-spacing: 0.3px;
        transition: color 0.3s;

        &.active,
        &:hover,
        &:active {
            font-weight: 500;
            color: $markCommonColor;
        }
    }

    &__search-container {
        width: 646px;
        min-width: 200px;
        margin-left: auto;
    }

    &__user-data {
        margin-left: auto;
        min-width: 180px;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-content: center;

        svg {
            fill: $navbarHeaderIconColor;
            path {
                fill: $navbarHeaderIconColor;
            }
        }

        .initials {
            background-color: $navbarHeaderIconColor;
            opacity: 1;
        }
    }

    &__user-data-notification {
        margin-left: auto;
        margin-top: 14px;

        .navbar__user-data-notification-icon-container {
            position: relative;
        }

        .navbar__user-data-notification-icon-container-content {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 2px;
            right: -14px;
            width: 25px;
            height: 25px;
            background-color: $headerCounterBackgroundColor;
            color: $defaultHighlightTextColor;
            border-radius: 50%;
            padding: 6px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    &__user-data-notification-with-counter {
        margin-right: 14px;
    }

    &__user-data-profile {
        margin-left: 16px;
        margin-top: 14px;
    }

    &__user-data-profile-icon {
        cursor: pointer;
        display: inline-block;
        width: 32px;
        height: 32px;
    }

    &__user-data-menu {
        position: absolute;
        margin-top: 2px;

        .wed-drop-down-menu {
            position: absolute;
            margin-right: 0;
            right: -32px;
        }
    }

    &__user-data-notification-button {
        cursor: pointer;
    }

    &__user-data-admin-panel {
        .navbar__user-data-notification {
            margin-left: 16px;
        }

        .navbar__user-data-admin-panel-box {
            margin-left: auto;
            margin-top: 14px;
        }

        .navbar__user-data-admin-panel-button {
            cursor: pointer;
        }
    }

    &__user-notifs {
        position: absolute;
        margin-top: 2px;

        .wed-drop-down-menu {
            position: absolute;
            margin-right: 0;
            right: -32px;
        }
    }

    &__user-information {
        padding-bottom: 6px;
        border-bottom: $sidebarBorderWhite;
        margin-bottom: 6px;
    }

    &__user-information-name {
        color: $defaultHighlightTextColor;
        font-family: Lato, sans-serif;
        font-size: 16px;
        text-align: center;
        padding: 0 12px;
    }

    &__user-information-email {
        color: $defaultSecondaryTextColor;
        font-family: Lato, sans-serif;
        font-size: 14px;
        padding: 0 12px;
        text-align: center;
    }

    @media screen and (max-width: 1200px) {
        &__search-container {
            display: none;
        }
    }

    @media screen and (max-width: 992px) {
        &__item {
            padding: 5px 10px;
        }

        &__icon {
            margin-right: 10px;
        }
    }
}

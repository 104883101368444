@import "../../styles/variables";

.wed-table {
    svg {
        vertical-align: middle;
    }

    th {
        td {
            color: #636363;
            white-space: nowrap;

            a {
                color: rgba(159, 159, 159, 0.7);
            }
        }
    }

    td {
        color: rgba(255, 255, 255, 0.7);
        white-space: nowrap;

        a {
            color: rgba(255, 255, 255, 0.9);
        }

        a:hover {
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .MuiTableCell-root {
        padding: 0 5px 0 0;
        line-height: 3.4;
    }

    .MuiTableCell-head {
        color: rgba(159, 159, 159, 0.7);
    }

    tbody {
        .MuiTableRow-root {
            user-select: none;
            cursor: pointer;

            &:nth-child(odd) {
                background-color: #2a2a2a;
            }

            td {
                border-bottom: none;

                .wed-icon-file-type-icon {
                    flex: 0 0 auto;
                    display: inline-flex;
                    align-items: center;
                    vertical-align: middle;

                    svg {
                        path {
                            fill: #adb0bb;
                        }
                    }
                }
            }

            &.table-row--active {
                background-color: $hoverActiveColor;
            }
        }

        .MuiTableRow-hover:hover {
            background-color: $hoverActiveColor;
        }
    }
}

.MuiTableContainer-root {
    overflow-x: unset !important;
}

@import "src/styles/variables";

.project__tile-menu {
    width: 48px;
    height: 48px;
    border-radius: $tileBorderRadius;
    background-color: #000;
    opacity: 0.6;
    padding: 8px;
}

@import "src/styles/variables";

.comment-box {
    padding-top: 24px;

    &--header {
        overflow: hidden;
        color: $sidebarHeaderColor;
        background-color: $sidebarBackgroundLighter;
        font-size: 18px;
        font-weight: 600;
    }

    &--header-text {
        padding: 0 40px;
    }

    &--header:after {
        margin-top: 15px;
        display: block;
        width: 100%;
        height: 1px;
        content: " ";
        overflow: hidden;
        background-color: $commentsLineColor;
        opacity: $commentsLineOpacity;
    }

    &--items {
        background: $sidebarBackgroundDarker;
    }

    &--form {
        padding-top: 12px;
    }

    .comment-thread {
        padding-top: 20px;
    }

    .comment-thread:last-child {
        padding-bottom: 20px;
    }
}

@import "src/styles/variables";

.information-summary-view-details {
    list-style: none;
    margin: 0;
    padding: 0;

    &--list-item {
        list-style: none;
        padding: 0;
        display: flex;
    }

    &--list-label {
        max-width: 30%;
        min-width: 100px;
        min-height: 35px;
        display: flex;
        flex-wrap: wrap;
    }

    &--list-label-content {
        align-self: flex-end;
        margin-bottom: 0;
        padding-right: 20px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.3px;
        color: $summaryBoxLabelColor;
        width: 100%;
        text-align: right;
    }

    &--list-value {
        width: 70%;
        min-height: 33px;
        display: flex;
        flex-wrap: wrap;

        a, a:hover, a:visited {
            color: #fff;
            text-decoration: none;
        }
    }

    &--list-value-content {
        align-self: flex-end;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.35px;
        color: #fff;
    }

    &--list-value-content-small {
        margin: 0 5px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.3px;
        color: $summaryBoxLabelColor;
    }

    &--status-label {
        text-transform: uppercase;
    }
}

@media screen and (max-width: 786px) {
    .information-summary-view-details {
        &--list-item {
            flex-direction: column;
        }

        &--list-label {
            min-height: auto;
        }

        &--list-value {
            min-height: auto;
            margin-bottom: 5px;
        }

        &--list-label-content {
            text-align: left;
        }
    }
}

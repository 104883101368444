@import "src/styles/variables";

.wed-icon {
    display: inline-block;

    svg path {
        fill: #{$iconDefaultColor};
    }

    &-action {
        cursor: pointer;
        transition: opacity 0.3s;
        &:hover {
            opacity: 0.7;
        }
    }
}

.wed-icon-size-small {
    width: 24px;
    height: 24px;
    font-size: 23px !important;
    line-height: 24px;
}

.wed-icon-size-normal {
    width: 32px;
    height: 32px;
    font-size: 31px !important;
}

.wed-icon-size-large {
    width: 42px;
    height: 42px;
    font-size: 41px !important;
}

.wed-icon-size-full {
    width: 100%;
    height: 100%;

    svg {
        width: 100%;
        height: 100%;
    }
}

.wed-icon-button {
    cursor: pointer;
    .wed-icon {
        svg path {
            fill: #{$iconControlColor};
        }
    }

    &:hover {
        svg path {
            fill: #{$iconControlHoverColor};
        }
    }

    &:disabled {
        cursor: default;
        svg path {
            fill: #{$iconControlDisabledColor};
        }
    }

    &:focus-visible {
        outline: none;
    }
}

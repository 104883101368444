@import "src/styles/variables";

.wed-main-menu-container {
    background-color: $headerBackgroundColor;
    display: flex;
    align-items: center;
    position: sticky;
    top: 119px;
    z-index: 15;

    &__content {
        display: flex;
        flex-direction: row;
        width: calc(100% - 451px);
    }

    &__menu {
    }

    &__buttons {
        margin-left: auto;
    }

    &__sidebar {
        width: 451px;
        margin-left: auto;
    }

    @media screen and (max-width: 1960px) {
        &__buttons {
            .MuiButton-root {
                font-size: 12px;
            }
        }
    }

    @media screen and (max-width: 1850px) {
        &__sidebar {
            width: 210px;
        }

        &__content {
            width: calc(100% - 241px);
        }
    }

    @media screen and (max-width: 1700px) {
        &__buttons {
            position: absolute;
            right: 0;
            width: 800px;
            margin-top: -50px;
        }
    }

    @media screen and (max-width: 1200px) {
        &__buttons {
            display: none;
        }
    }

    @media screen and (max-width: 960px) {
        &__sidebar {
            display: none;
        }
    }
}

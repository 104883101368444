@import "src/styles/variables";

.sidebar-menu {
    height: calc(100% - 104px);

    &__container {
        margin: 0;
        list-style: none;
        display: flex;
        background-color: $sidebarBackgroundLighter;
        padding: 6px 0 0 0;
    }

    &__label {
        height: 60px;
        width: 50%;
        font-size: 18px;
        display: flex;
        align-items: center;
        border-bottom: $sidebarBorderWhite;
        color: $sidebarDefaultColor;
        cursor: pointer;
        transition: border-bottom-color 0.2s, color 0.2s;
        padding-left: 40px;
        font-weight: 300;

        &:hover,
        &--active {
            font-weight: 600;
            color: $defaultHighlightTextColor;
            border-bottom: 2px solid $markCommonColor;
        }
    }

    &__content {
        height: calc(100% - 66px);
    }
}

@import "src/styles/variables";

.information-summary {
    &--column-grid {
        display: flex;
        flex-direction: row;
    }

    &--column-grid-left {
        margin-right: auto;
        max-width: 430px;
        width: 45%;
    }

    &--column-grid-right {
        margin-left: auto;
        width: 50%;
    }

    &--description {
    }

    .MuiInputBase-root.Mui-disabled {
        color: #fff !important;
    }
}

@media screen and (max-width: 1024px) {
    .information-summary {
        &--column-grid {
            display: flex;
            flex-direction: column;
        }

        &--column-grid-left {
            width: 100%;
        }

        &--column-grid-right {
            width: 100%;
        }
    }
}

@import "src/styles/variables";

.client-contacts-page {
    &--container {
        margin: $defaultContentContainerMarginWithoutSidebar;
    }

    .client-contacts-tile__create-client-contact {
        height: #{$tileClientContactHeight};
    }

    &--wed-table-name-cell-create-by {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
    }

    &--wed-table-name-cell-initials {
        width: 48px;
    }
}

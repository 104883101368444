.edit-client-popup {
    &--columns {
        display: flex;
        flex-direction: row;
    }

    &--column-item {
        width: calc(50% - 12px);

        &:first-child {
            margin-right: auto;
        }

        &:last-child {
            margin-left: auto;
        }
    }
}

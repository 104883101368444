@import "src/styles/variables";

.tag-item {
    display: inline-block;
    max-width: 200px;

    &--container {
        display: inline-flex;
        height: 32px;
        border-radius: 3px;
        background-color: $tagItemBackground;
        align-items: center;
        max-width: 100%;
    }

    &--value {
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        letter-spacing: 0.3px;
        color: $tagItemFontColor;
        padding: 0 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &--remove-icon {
        border: 0;
        padding: 0;
        margin: 0 3px 0 0;
        background: transparent;
    }
}

@import "../../styles/variables";

.header {
    background-color: $headerBackgroundColor;
    z-index: 100;
    position: fixed;
    width: 100%;
    top: 0;
    min-height: 119px;

    &__container {
        padding: 0 65px 10px;
    }

    &--shared,
    &--admin-panel {
        min-height: 0;
        padding: 15px 0;

        .header__container {
            padding: 0 65px;
        }
    }
}

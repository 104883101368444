@import "src/styles/variables";

.user-my-change-password-popup {
    &--content-user-info-row {
        display: flex;
        margin: 12px 0;
    }

    &--content-user-info-row-label {
        color: $defaultFormLabelColor;
        width: 140px;
    }

    &--content-user-info-row-label-value {
        font-weight: 500;
    }
}

@import "src/styles/variables";

.wed-media-gallery-strip {
    height: 100%;

    &__loading {
        img {
            opacity: 0.01 !important;
            width: 1px !important;
            height: 1px !important;
        }

        .wed-media-gallery-image__icon {
            visibility: hidden;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__content {
        width: calc(100% - 80px);
        height: 100%;
        margin: 0 auto;
    }

    ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        height: 100%;
    }

    li {
        display: block;
        height: 100%;
        margin-left: 6px;

        &:first-child {
            margin-left: 0;
        }

        .wed-media-gallery-image__clickable {
            height: 100%;
        }
    }

    .wed-media-gallery-image {
        height: 100%;

        .wed-media-gallery-image__img {
            height: 70px;
            border-radius: $tileBorderRadius;
        }

        .wed-media-gallery-image__blank {
            height: 70px;
            border-radius: $tileBorderRadius;
            background-color: #000000;
            opacity: $defaultImageBoxBlankOpacity;
        }

        .wed-icon {
            opacity: $defaultImageContentOpacity;
        }

        .wed-media-gallery-image__icon {
            width: 70px;
            height: 70px;

            .wed-icon {
                width: 50px;
                height: 50px;
            }
        }
    }

    li {
        .wed-media-gallery-image-active,
        .wed-media-gallery-image__clickable:hover {
            .wed-media-gallery-image__img {
                border-color: rgba(255, 255, 255, 0.6);
            }

            .wed-media-gallery-image__icon {
                border-color: $defaultBorderColor;
            }

            .wed-media-gallery-image__blank {
                opacity: 0;
            }

            .wed-icon {
                opacity: 1;
            }
        }
    }

    .wed-media-gallery-image__img,
    .wed-media-gallery-image__icon {
        border-radius: $tileBorderRadius;
        border-color: transparent;
        border-style: solid;
        border-width: 1px;
    }
}

.wed-media-gallery {
    .wed-media-gallery-strip {
        .wed-icon-button-next,
        .wed-icon-button-prev {
            margin-bottom: 14px;
        }
    }
}

@import "src/styles/variables";

.summary-page-layout {
    margin: $defaultContentContainerMarginWithoutSidebar;

    &--container {
        display: flex;
        flex-direction: row;
    }

    &--box-content {
        width: 50%;
        background-color: $summaryBoxBackgroundColor;
        border-radius: $summaryBoxBorderRadius;

        &:first-child {
            margin-right: 16px;
        }
    }

    &--extra {
        margin-bottom: 24px;
        display: grid;
        grid-gap: 24px;
    }

    &--extra-3-box {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }

    &--extra-box-content {
        background-color: $summaryBoxBackgroundColor;
        border-radius: $summaryBoxBorderRadius;
    }
}

@media screen and (max-width: 1800px) {
    .summary-page-layout {
        &--container {
            flex-direction: column;
        }

        &--box-content {
            width: auto;
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .summary-page-layout {
        &--extra-3-box {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
    }
}

@import "src/styles/variables";

.sidebar-initials {
    &__row {
        display: grid;
        grid-template-columns: 100px auto;
        margin: 8px 0 0 0;
        align-items: baseline;
    }

    &__initials {
        width: 272px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
        grid-gap: 8px;
    }

    &__label {
        font-size: 12px;
        letter-spacing: 0.3px;
        color: $sidebarDarkLabelColor;
        min-height: 32px;
    }
}

.admin-panel-icon-button {
    display: flex;
    justify-content: center;

    .wed-icon-button {
        background-color: transparent;
        border: 0;

        .wed-icon {
            height: auto;
            font-size: 1px !important;
        }
    }
}

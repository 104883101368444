@import "src/styles/variables";

.wed-drop-down-menu-item {
    &__label {
        margin-left: 11px;
        color: $defaultHighlightTextColor;
        font-family: Lato, sans-serif;
        font-size: 16px;

        &--disabled {
            opacity: 0.5;
        }
    }

    &__divider {
        width: 100%;
        height: 1px;
        background-color: #393a3e;
    }
}

@import "src/styles/variables";

.admin-panel-subscription {
    &--column-text {
        display: inline-block;
        line-height: 1.4rem;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &--column-price {
        white-space: nowrap;
    }

    &--revoke-pending-cancellation {
        width: 60%;
        margin: 0px auto;

        color: $adminPanelExtraActionText;

        b {
            color: $defaultFormLabelColor;
        }
    }

    &--revoke-pending-cancellation-button {
        margin-top: 24px;
        text-align: center;
    }

    &--extra-cancel-box {
        color: $adminPanelExtraActionText;

        a {
            color: $defaultFormLabelColor;
            text-decoration: none;
        }

        a:hover {
            color: $adminPanelActiveColor;
        }
    }
}

@import "src/styles/variables";

.filter-form-box {
    min-width: 80px;

    .MuiFormLabel-root {
        font-weight: 500;
    }

    &__search-icon {
        &:hover {
            svg path {
                fill: rgba(255, 255, 255, 0.8);
            }
        }

        svg path {
            fill: $filterFormColor;
        }
    }

    &__container {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    &__item {
        margin-left: 24px;
    }

    &__autocomplete {
        .MuiTextField-root {
            .MuiInputBase-root {
                height: 32px;
                min-height: 32px;
                color: $filterFormColor;
                padding-top: 7px;

                .MuiOutlinedInput-input {
                    padding-top: 0;
                }
            }

            .MuiInputLabel-root {
                transform: none;
                top: 9px;
                left: 10px;

                &.MuiInputLabel-shrink {
                    transform: none;
                    display: none;
                }
            }
        }

        fieldset {
            margin-top: 5px;

            legend {
                display: none;
            }
        }
    }

    &__container {
        .MuiOutlinedInput-input {
            padding-top: 0;
        }

        .MuiSelect-selectMenu {
            line-height: 33px;
        }
    }
}

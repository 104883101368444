@import "src/styles/variables";

.search-input {
    margin: 14px;

    &__control {
        border-radius: $buttonBorderRadius;
        border: solid 1px rgba(255, 255, 255, 0.15) !important;
        background-color: #0f0f0f;
        height: 32px;
        padding: 0 4px;
    }

    .MuiInputAdornment-positionStart {
        .wed-icon {
            margin-left: 4px;
            opacity: 0.6;
        }
    }

    .MuiInputAdornment-positionEnd {
        cursor: pointer;
        .wed-icon {
            margin-right: 3px;
            opacity: 0.6;
        }
    }

    input {
        font-size: 14px;
        padding-top: 8px;
        margin-left: 13px;
        text-align: left;
        color: $defaultSecondaryTextColor;
    }
}

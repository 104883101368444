@import "src/styles/variables";

.admin-page-secondary-menu {
    background-color: $headerBackgroundColor;
    display: flex;
    justify-content: center;
    padding-left: 0;
    height: 48px;

    &.main-menu {
        a {
            margin-right: 24px;
        }
        span {
            width: auto;
        }
    }
}

.breadcrumb-collapse {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 3px;

    &--open,
    &:hover {
        background-color: #2f434f;

        svg.MuiSvgIcon-root {
            fill: rgba(250, 250, 250, 0.8);
        }
    }

    svg {
        fill: rgba(250, 250, 250, 0.4);
    }

    &__menu {
        position: absolute;
        z-index: 1;
        top: 32px;
        left: 0;
        display: none;

        &--active {
            display: block;
        }
    }
}

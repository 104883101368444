@import "src/styles/variables";

.not-logged-page {
    margin: 0;
    padding: 130px 0 0 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    a {
        text-decoration: none;
        color: $defaultTextColor;
        font-size: 0.875rem;
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        line-height: 1.43;
    }

    a:hover {
        color: $defaultHighlightTextColor;
    }
}

@import "../../../styles/variables";

.header {
    .navbar {
        display: flex;
        align-items: center;

        .popup-close-icon {
            top: 4px;

            svg {
                font-size: 40px;
            }
        }
    }
}

.header--admin-panel {
    .navbar {
        .header--admin-panel-title {
            color: $navbarHeaderIconColor;
            font-size: 22px;
        }
    }
}

@import "src/styles/variables";

.sidebar-button-icon {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;

    .wed-icon svg path {
        fill: $sidebarDefaultColor;
    }

    &:disabled {
        cursor: default;
        opacity: 0.6;
    }
}

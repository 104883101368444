.main-menu.user-team-menu {
    li a span {
        width: 110px;
    }

    li.user-team-menu--wide a span {
        width: 150px;
    }
}

@media screen and (max-width: 1980px) {
    .main-menu.user-team-menu {
        li a span {
            width: 110px;
        }

        li.user-team-menu--wide a span {
            width: 150px;
        }
    }
}

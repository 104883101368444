@import "src/styles/variables";

.sidebar-wrapper {
    display: flex;

    &__page-content {
        padding: 0 65px;
        margin-bottom: 70px;
        width: 100%;
    }

    &__sidebar {
        position: -webkit-sticky;
        position: fixed;
        top: 167px;
        right: 0;
        height: calc(100vh - 167px);
        background-color: $sidebarBackgroundLighter;
        display: block;
        -webkit-transition: width 0.3s;
        transition: width 0.3s;
        width: 0;
    }

    @media screen and (min-width: #{$sidebarMinWidth}) {
        &--active {
            .sidebar-wrapper__page-content {
                width: calc(100% - 451px);
                padding-right: 26px;
            }

            .sidebar-wrapper__sidebar {
                width: 451px;
            }
        }
    }

    @media screen and (max-width: 500px) {
        &__page-content {
            padding: 0 35px;
        }
    }

    @media screen and (max-width: 450px) {
        &__page-content {
            padding: 0 10px;
        }
    }
}

$defaultBorderRadius: 6px;
$defaultTextColor: rgba(255, 255, 255, 0.65);
$defaultSecondaryTextColor: rgba(250, 250, 250, 0.6);
$defaultBackground: #171717;
$defaultBorderColor: $defaultTextColor;
$defaultSecondBackground: #191919;
$defaultHighlightTextColor: #fafafa;
$defaultImageBoxBlankOpacity: 0.3;
$defaultImageContentOpacity: 0.7;
$defaultBackgroundColor: #232323;
$defaultFormLabelColor: #9f9f9f;
$defaultFormSecondaryColor: #707070;
$defaultContentBackgroundColor: #1f1f1f;
$defaultContentContainerMarginWithoutSidebar: 91px 65px 33px 65px;
$white: #fff;
$markCommonColor: #ce9d02;
$blueColor: rgb(60, 96, 116);
$inactiveColor: #ff002a;
$activeColor: #63bd1a;
$hoverActiveColor: #2f434f;
$navbarHeaderIconColor: #e3ddde;
$headerCounterBackgroundColor: $markCommonColor;
$headerBackgroundColor: $defaultBackgroundColor;
$headerNotifsMessage: $defaultTextColor;
$headerNotifsHighlight: $defaultHighlightTextColor;
$statusDotColorApproved: $activeColor;
$statusDotColorRejected: $inactiveColor;
$statusDotColorInProgress: #1648fa;
$statusDotColorRequested: #8a8a8a;
$tileProjectHeight: 338px;
$tileDirectoryHeight: 284px;
$tileFileHeight: 413px;
$tileCollectionHeight: 450px;
$tileClientHeight: 338px;
$tileClientContactHeight: 338px;
$tileBorderRadius: $defaultBorderRadius;
$tileDefaultTextColor: $defaultTextColor;
$tileHighlightTextColor: $defaultHighlightTextColor;
$tileBackgroundColor: $defaultContentBackgroundColor;
$tileTextDarkColor: #797979;
$mediaGalleryBackgroundColor: $tileBackgroundColor;
$mediaGalleryBorderRadius: $defaultBorderRadius;
$iconDefaultColor: rgba(255, 255, 255, 0.5);
$iconControlColor: $defaultTextColor;
$iconControlHoverColor: rgba(255, 255, 255, 0.8);
$iconControlDisabledColor: rgba(255, 255, 255, 0.4);
$buttonBorderRadius: $defaultBorderRadius;
$wedMainMenuButtonColor: $defaultHighlightTextColor;
$wedMainMenuButtonDisabledColor: #6c6c6c;
$wedMainMenuButtonHoverColor: #ffffff;
$filterFormColor: $defaultTextColor;
$mediaGalleryIconColor: $defaultTextColor;
$mediaGalleryIconHoverColor: $defaultHighlightTextColor;
$mediaGalleryIconBorderRadius: $defaultBorderRadius;
$mediaGalleryIconBorderColor: $defaultTextColor;
$mediaGalleryIconBorderHoverColor: $defaultHighlightTextColor;
$tagItemFontColor: rgba(159, 159, 159, 0.6);
$tagItemBackground: #121212;
$sidebarMinWidth: 1800px;
$sidebarHeaderColor: $defaultHighlightTextColor;
$sidebarActiveColor: $defaultHighlightTextColor;
$sidebarDefaultColor: rgba(250, 250, 250, 0.75);
$sidebarDarkLabelColor: rgba(159, 159, 159, 0.6);
$sidebarValueLabelColor: #9f9f9f;
$sidebarDarkText: $defaultFormSecondaryColor;
$sidebarDarkIcon: $wedMainMenuButtonDisabledColor;
$sidebarDescriptionColor: $defaultSecondaryTextColor;
$sidebarBackgroundLighter: #292929;
$sidebarBackgroundDarker: #202020;
$sidebarBorderWhite: 1px solid rgba(250, 250, 250, 0.2);
$sidebarActivityHeader: $white;
$sidebarActivityDate: $defaultFormSecondaryColor;
$userCardInitialsBackground: #e3ddde;
$userCardNameColor: #fff;
$userCardEmailColor: $defaultFormSecondaryColor;
$summaryBoxBackgroundColor: $defaultContentBackgroundColor;
$summaryBoxBorderRadius: $defaultBorderRadius;
$summaryBoxHeaderColor: $defaultHighlightTextColor;
$summaryBoxLabelColor: $defaultFormLabelColor;
$summaryTeamDefaultColor: rgba(255, 255, 255, 0.7);
$membersEmail: $defaultFormSecondaryColor;
$commentsDefaultColor: $defaultFormLabelColor;
$commentsDarkColor: $defaultFormSecondaryColor;
$commentsContentColor: $defaultSecondaryTextColor;
$commentsLineColor: $defaultHighlightTextColor;
$commentsLineOpacity: 0.4;
$initialsExternalUserColor: rgb(206, 157, 2);
$initialsInternalUserColor: rgb(60, 96, 118);
$notLoggedPageBorderRadius: $defaultBorderRadius;
$notLoggedPageBackgroundColor: rgba(31, 31, 31, 0.75);
$adminPanelDefaultColor: $defaultFormLabelColor;
$adminPanelActiveColor: $defaultHighlightTextColor;
$adminPanelExtraActionText: rgba(159, 159, 159, 0.7);

@import "src/styles/variables";

.trash {
    &__table-container {
        padding: 9px 40px;
    }

    &__cell-folder-open-icon {
        padding-right: 10px;
    }

    &__cell-with-2-icons {
        width: 88px;

        input {
            color: rgba(176, 184, 200, 0.75);
        }
    }

    &__cell-with-folder-icon {
        width: 42px;
    }

    &__cell-action-icons {
        width: 125px;
    }

    &__trash__folder-icon {
        color: rgba(250, 250, 250, 0.7);

        &:hover {
            color: rgba(250, 250, 250, 0.9);
        }
    }

    &__action-icons {
        a {
            margin-right: 12px;
        }
    }

    &__table-empty-content {
        margin-left: -38px;
        margin-bottom: 40px;
    }

    &__cell-localization {
        max-width: 175px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .wed-icon-button {
        width: 24px;
        height: 24px;
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;
    }

    .wed-table {
        td.trash__cell-file-name {
            color: #fff;
        }

        td.trash__cell-initials {
            span.trash__cell-initials-outer {
                display: block;
                width: 32px;
                height: 32px;
                border-radius: 3px;
                background-color: rgba(255, 255, 255, 0.7);
            }

            span.trash__cell-initials-inner {
                font-weight: bold;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $defaultBackgroundColor;
            }
        }

        tbody {
            .MuiTableRow-root {
                &:nth-child(odd) {
                    td.trash__cell-initials {
                        span.trash__cell-initials-inner {
                            color: #2a2a2a;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .MuiTableCell-root.trash__hide-cell-1280 {
            display: none;
        }
    }

    @media screen and (max-width: 960px) {
        .MuiTableCell-root.trash__hide-cell-960 {
            display: none;
        }
    }

    @media screen and (max-width: 640px) {
        .MuiTableCell-root.trash__hide-cell-640 {
            display: none;
        }
    }

    @media screen and (max-width: 480px) {
        .MuiTableCell-root.trash__hide-cell-480 {
            display: none;
        }
        .MuiTableCell-root.trash__cell-file-name {
            padding-left: 5px;
        }
        .MuiTableCell-root.trash__header-file-name {
            padding-left: 5px;
        }
    }
}

@import "src/styles/variables";

.admin-panel-page {
    &__container {
        margin-top: 70px;
        display: grid;
        grid-template-columns: 300px auto;
        height: calc(100vh - 70px);
    }
}

@import "src/styles/variables";

.main-menu {
    padding-left: 65px;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;

        a {
            display: block;
            text-decoration: none;
            border-bottom: 2px solid $headerBackgroundColor;
            margin-right: 30px;

            span {
                display: block;
                color: white;
                text-align: center;
                text-decoration: none;
                height: 46px;
                font-size: 14px;
                width: 130px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.14px;
                color: rgba(216, 216, 216, 0.7);
                line-height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                border-bottom: 2px solid $markCommonColor;

                span {
                    color: #fff;
                }
            }
        }
    }

    .main-menu__active {
        a {
            border-bottom: 2px solid $markCommonColor;

            span {
                color: #fff;
            }
        }
    }

    @media screen and (max-width: 2100px) {
        li {
            a {
                margin-right: 15px;

                span {
                    width: 110px;
                }
            }
        }
    }

    @media screen and (max-width: 1980px) {
        li {
            a {
                margin-right: 5px;

                span {
                    width: 105px;
                }
            }
        }
    }
}

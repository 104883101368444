html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  height: auto;
}

body.not-logged-popup-page--body-container {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.grecaptcha-badge {
  visibility: hidden;
}

.wed-redirect-post-form {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  background: red;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.upload-file {
    &__box-center {
        min-height: 100px;
        text-align: center;
        cursor: pointer;
        padding-top: 38px;
    }

    &__list {
        height: 190px;
        margin-top: 20px;
        &_1 {
            height: 63px;
        }
        &_2 {
            height: 126px;
        }
    }

    &__list-item {
        margin-top: 10px;
    }

    &__list-content-scrollable {
        width: calc(100% - 30px);
    }

    &__list-item-file-size {
        margin-left: 10px;
    }

    .CircularProgressbar {
        width: 30px;
        height: 30px;
        visibility: hidden;
    }

    .CircularProgressbar-path {
        stroke: #fff;
    }

    .CircularProgressbar-trail {
        stroke: rgba(0, 0, 0, 0.125);
    }

    .MuiAlert-message {
        padding: 11px 0;
    }

    .MuiAlert-root {
        margin-top: 10px;

        &:first-child {
            margin-top: 0px;
        }
    }

    &__list-item-uploading {
        .CircularProgressbar {
            visibility: visible;
        }
    }

    .popup-action {
        padding-right: 0;
    }
}

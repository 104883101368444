@import "src/styles/variables";

.user-notifs {
    width: 380px;

    .wed-icon-button {
        width: 32px;
        height: 32px;
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;
    }

    &--header {
        color: $headerNotifsMessage;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;

        span {
            margin-left: 10px;
            cursor: pointer;
        }

        span:hover {
            color: $headerNotifsHighlight;
        }
    }

    &--container {
        height: 180px;
    }

    &--container-cnt2 {
        height: 100px;
    }

    &--container-cnt1 {
        height: 60px;
    }

    &--container-empty {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiButtonBase-root {
        cursor: default;
    }

    .MuiMenuItem-root {
        width: 100%;
        white-space: unset;
    }

    &--empty-box {
        text-align: center;
    }

    &--item {
        width: 100%;
        display: flex;
    }

    &--item-message {
        width: calc(100% - 48px);
        display: flex;
        align-items: center;
        justify-content: left;
        font-size: 13px;
        letter-spacing: 0.35px;
        line-height: 1.2;
        color: $headerNotifsMessage;
    }

    &--action {
        width: 32px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@import "src/styles/variables";

.action-tile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: transparent;
    border: 2px dashed #292929;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: $tileBackgroundColor;
        border-radius: 50%;

        svg {
            font-size: 16px;
            fill: $defaultHighlightTextColor;
        }
    }

    &__title {
        position: absolute;
        text-align: center;
        transform: translateY(50%) translateY(35px);
    }
}

@import "src/styles/variables";

.comment-note {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;

    &--container {
        display: flex;
        flex-direction: column;
        padding: 0 40px;
        width: 100%;
    }

    &--container:hover {
        background-color: $sidebarBackgroundLighter;
    }

    &--wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &--initials {
        display: flex;
        flex-direction: column;
        width: 50px;
    }

    &--initials:after {
        margin-left: 16px;
        width: 1px;
        height: calc(100% - 32px);
        content: " ";
        background-color: $commentsLineColor;
        display: none;
        overflow: hidden;
        opacity: $commentsLineOpacity;
    }

    &--container-line-wrapper {
        height: 10px;
        width: 100%;
    }

    &--container-line {
        height: 100%;
        margin-left: 16px;
        width: 1px;
        content: " ";
        background-color: $commentsLineColor;
        display: none;
        overflow: hidden;
        opacity: $commentsLineOpacity;
    }

    &--content {
        width: calc(100% - 50px);
    }

    &--content-top {
        display: flex;
        flex-direction: row;
    }

    &--content-user {
        width: calc(100% - 40px);
    }

    &--content-actions {
        width: 32px;
        margin-left: auto;
    }

    &--content-header {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        color: $commentsDefaultColor;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &--content-created {
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 0.25px;
        color: $commentsDarkColor;
        height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &--content-description {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.33;
        letter-spacing: 0.3px;
        color: $commentsContentColor;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    button:disabled {
        cursor: default;
    }
}

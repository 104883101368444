@import "src/styles/variables";

.user-team-tile {
    height: #{$tileClientContactHeight};

    &__header {
        display: flex;
        flex-direction: row;
    }

    &__profile-image {
        width: 100px;

        .initials {
            width: 100px;
            height: 100px;
            font-size: 38px;
        }
    }

    &__profile-info {
        width: calc(100% - 124px);
        margin-left: auto;
    }

    &__profile-info-name {
        width: calc(100% - 40px);

        .tile-title {
            display: flex;
            align-items: flex-end;
            height: 60px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
        }
    }

    &__profile-info-position {
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 2;
        display: flex;

        span {
            align-self: flex-end;
        }
    }

    &--extra-info {
        margin-top: 10px;
        height: 100px;
    }

    &__label-value {
        padding-top: 10px;
        font-size: 12px;
        letter-spacing: 0.35px;
        line-height: 1.7;
        color: $tileDefaultTextColor;
        display: flex;
        flex-direction: row;
    }

    &__label-value-label {
        width: 100px;
        font-weight: 600;
        text-align: left;
    }

    &__label-value-value {
        width: calc(100% - 160px);
        text-align: right;
    }

    &__details {
        display: flex;
        align-items: center;
        margin-top: 16px;
        color: $tileTextDarkColor;
        font-size: 11px;
    }

    &__date,
    &__user {
        color: $tileDefaultTextColor;
        padding: 0 4px;
    }

    &__projects-info {
        margin-top: 25px;
        color: $tileTextDarkColor;
        font-size: 11px;
    }

    &__projects-info-value {
        margin-left: 8px;
        color: $tileDefaultTextColor;
        font-weight: 600;
        display: inline-block;

        span {
            display: inline-block;
            font-weight: normal;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

@import "src/styles/variables";

.team-wed-table {
    .wed-table {
        .team-wed-table--no-display {
            display: none;
            border-bottom-color: transparent;
        }

        .MuiTableHead-root:first-child {
            .team-wed-table--label-container {
                margin-top: 0;
            }
        }

        .MuiTableCell-root {
            line-height: normal;
        }

        tbody {
            .MuiTableRow-root {
                cursor: default;
            }

            .MuiTableRow-root:nth-child(odd) {
                background-color: transparent;
            }
        }

        .MuiTableCell-root {
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: rgba(255, 255, 255, 0.15);
        }

        .MuiTableRow-head .MuiTableCell-root {
            border-bottom-width: 2px;
        }
    }

    &--customer-contacts {
        .team-wed-table--label-container {
            margin: 34px 0 12px 0;
        }

        .team-wed-table--label-container-first-item {
            margin-left: 35px;
        }
    }

    &--team-members {
        .team-wed-table--label-container {
            margin: 0 0 12px 0;
        }

        .team-wed-table--label-container-first-item {
            margin-left: 35px;
        }
    }

    &--cell-container {
        color: $summaryTeamDefaultColor;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.3px;
    }

    &--cell-action {
        width: 80px;
    }

    .wed-icon-button {
        border: none;
        margin: 0;
        padding: 0;
        background-color: transparent;
        display: flex;
        height: 100%;
        align-items: center;

        .wed-icon {
            display: flex;
        }
    }
}

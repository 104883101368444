@import "src/styles/variables";

.sidebar-preview {
    height: 100px;
    background-color: $sidebarBackgroundDarker;

    .wed-image {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        display: block;
    }

    &__icon {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.project-files-buttons {
    &__collections {
        display: inline-block;
        position: relative;
    }

    &__collections-menu {
        position: absolute;
        z-index: 1;
        top: 34px;
        right: 0;
        display: none;

        &--active {
            display: block;
        }
    }
}

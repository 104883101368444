.not-logged-form-page {
    &--buttons {
        margin-top: 32px;
        margin-bottom: 20px;
    }

    &--buttons-flex {
        display: flex;
        align-items: center;

        .MuiButton-root {
            white-space: nowrap;
        }
    }

    &--form-row {
        display: flex;
    }

    &--form-row-column50 {
        width: calc(50% - 12px);
    }

    &--form-row-column50:last-child {
        margin-left: auto;
    }

    &--captcha-term {
        font-size: 13px;
        line-height: 1.15;
        margin-left: 24px;

        a {
            font-size: 13px;
            line-height: 1.15;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.not-logged-popup-page--with-logo {
    .not-logged-form-page--form {
        padding: 0 65px;
    }
}

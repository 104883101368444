@import "src/styles/variables";

.user-base-company-data-form {
    &--buttons {
        margin-top: 32px;
        margin-bottom: 20px;
    }

    &--form-row {
        display: flex;
    }

    &--form-row-column50 {
        width: calc(50% - 12px);
    }

    &--form-row-column50:last-child {
        margin-left: auto;
    }

    &--select-plan-header {
        color: $defaultTextColor;
        font-size: 1rem;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    &--select-plan-label {
        margin-left: 12px;
    }

    &--select-plan-description {
        text-align: left;
    }

    &--select-plan-price {
        text-align: center;
    }

    &--select-plan-action {
        text-align: center;
    }
}

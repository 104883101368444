.collection-preview-media {
    position: relative;
    height: 255px;

    &__image-main {
        float: left;
        width: 254px;
        height: 255px;
    }

    &__image-small {
        float: right;
        width: calc(100% - 258px);
        height: 126px;

        &:nth-child(3) {
            margin-top: 3px;
            height: 127px;
        }
    }

    &__image-large {
        float: left;
        width: 100%;
        height: 255px;
    }
}

@import "../../../../styles/variables";

.breadcrumb-separator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $markCommonColor;
    font-weight: 900;
    margin: 0 6px;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    transition: background-color 0.2s;
    cursor: pointer;

    &--open {
        &:before {
            transform: rotate(90deg);
        }
    }

    &:before {
        content: ">";
    }

    &:hover,
    &--open {
        background-color: $hoverActiveColor;
    }

    &--disabled {
        cursor: default;
        &:hover,
        &--open {
            background-color: transparent;
        }
    }

    &__menu {
        display: none;
        position: absolute;
        top: 32px;
        z-index: 1;

        &--active {
            display: block;
        }

        &--left {
            right: 0;
        }

        &--right {
            left: 0;
        }
    }
}

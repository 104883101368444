.admin-panel-table {
    .wed-table tbody .MuiTableRow-root {
        cursor: default;
    }

    .MuiTableRow-root {
        .MuiTableCell-root {
            padding-left: 10px;
        }
    }

    &--center.MuiTableCell-root {
        text-align: center;
        justify-content: center;
    }
}

@import "src/styles/variables";

.project-status {
    border: solid 2px #797979;
    padding: 4px 15px 4px 12px;
    font-size: 14px;
    border-radius: 3px;
    margin-top: 9px;
    font-weight: 500;

    &--active {
        color: $markCommonColor;
    }
}

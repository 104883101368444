@import "src/styles/variables";

.container-grid {
    &__header {
        font-weight: 500;
        font-size: 12px;
        padding-left: 28px;
        letter-spacing: 0.3px;
        color: $tileDefaultTextColor;
    }

    &__tiles {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
        grid-gap: 24px;
        margin-top: 23px;
        margin-bottom: 40px;
    }

    &__wide {
        .container-grid__tiles {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
    }
}

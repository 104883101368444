@import "src/styles/variables";

.collection_share-icon {
    margin-top: 5px;

    &__share-icon-shared-external {
        color: $markCommonColor;

        svg {
            path {
                fill: $markCommonColor;
            }
        }
    }

    &__share-icon-shared-internal {
        color: $blueColor;

        svg {
            path {
                fill: $blueColor;
            }
        }
    }
}

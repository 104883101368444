.admin-panel-billing-payment-method {
    &--label-by {
        margin-left: 5px;
        margin-right: 5px;
    }

    &-content {
        margin-top: 157px;
    }

    .admin-panel-container-text-table table tr.admin-panel-billing-payment-method--first-row td {
        padding-bottom: 24px;
    }

    .admin-panel-container-text-table table tr.admin-panel-billing-payment-method--last-row td {
        padding-top: 24px;
    }
}

.user-base-profile-popup {
    &--container {
        display: flex;
        flex-direction: row;
    }

    &--content {
        width: calc(100% - 300px);
    }

    .popup-header {
        width: calc(100% - 300px);
    }

    &--icon {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

        .wed-icon {
            width: 160px;
            height: 160px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .MuiDialogActions-root {
        padding-right: 324px;
    }
}

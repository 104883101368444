.approval-request {
    .popup-header {
        margin-left: -70px;
        margin-right: -70px;
    }

    &--content {
        min-height: 400px;
    }

    &--popup-action-hide {
        visibility: hidden;
    }

    &--row {
        display: flex;
        flex-direction: row;
    }

    .approval-request--row-col-left {
        width: 55%;
    }

    .approval-request--row-col-right {
        width: 40%;
        margin-left: auto;
    }

    &--row {
        .approval-request--row-cell {
            height: 34px;
            display: flex;
            margin-left: auto;
            align-items: center;
            justify-content: left;
        }

        .approval-request--row-col-left {
            .approval-request--row-cell {
                width: 70%;
            }
        }

        .approval-request--row-col-right {
            .approval-request--row-cell {
                width: 100%;
                justify-content: center;
            }
        }
    }

    &--row-header {
        .approval-request--row-cell {
            height: 40px;
            font-weight: 600;
            text-align: center;
        }

        .approval-request--row-col-left {
            .approval-request--row-cell {
                margin-left: 0;
                justify-content: center;
                width: 100%;
            }
        }
    }
}

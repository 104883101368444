.admin-panel-manage-current-users {
    &--dropdown-user-role {
        .wed-inside-form-element .MuiInputBase-root .MuiSelect-select {
            min-width: 80px;
        }
    }

    &--change-status-container {
        display: flex;
        justify-content: center;
    }

    &--change-status {
        display: flex;
        align-items: center;
    }

    &--change-status-label {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--change-status-label-disabled {
        opacity: 0.7;
    }
}

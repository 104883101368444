@import "src/styles/variables";

.share-content-header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.18px;
    color: $sidebarActiveColor;

    &__default {
        padding: 23px 0;
    }

    .wed-icon {
        margin-right: 16px;
    }

    small {
        margin: 0 10px;
        color: $sidebarValueLabelColor;
    }

    .filter-form-share-collection {
        margin-left: auto;
    }
}

@import "src/styles/variables";

.wed-media-gallery-image {
    position: relative;

    &--main-media-container {
        width: 100%;
        height: 100%;
    }

    &--main-media-annotation {
        width: 100%;
        height: 100%;
    }

    &--main-media-transform {
        width: 100%;
        height: 100%;
    }

    &--main-media-no-display {
        display: none;
    }

    &__clickable {
        cursor: pointer;
    }

    &__blank {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__icon {
        border-radius: $mediaGalleryIconBorderRadius;
        border: solid 1px $mediaGalleryIconBorderColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg path {
            fill: $mediaGalleryIconColor;
        }
    }

    &__clickable:hover {
        .wed-media-gallery-image__icon {
            border-color: solid 1px $mediaGalleryIconBorderHoverColor;

            svg path {
                fill: $mediaGalleryIconHoverColor;
            }
        }
    }

    &__img {
        object-fit: contain;
    }

    &__video {
        width: 100%;
        height: 100%;
    }

    &__pdf {
        width: 100%;
        height: 100%;
        border-radius: $mediaGalleryIconBorderRadius;
        border: solid 1px $mediaGalleryIconBorderColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .wed-media-gallery-image__pdf-container {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            display: flex;
            align-items: center;
            justify-content: center;

            iframe {
                padding: 0;
                margin: 0;
                border: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@import "src/styles/variables";

.edit-client-account-popup {
    .MuiDialogActions-root {
        padding-right: 324px;
    }

    &--content {
        display: flex;
        flex-direction: row;
    }

    &--form {
        width: calc(100% - 300px);
    }

    .popup-header {
        width: calc(100% - 300px);
    }

    &--icon-image {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

        .wed-icon {
            width: 160px;
            height: 160px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &--header {
        font-weight: 500;
        font-size: 18px;
        margin: 0 0 20px 0;

        label {
            color: $defaultFormLabelColor;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            margin-right: 10px;
        }
    }

    &--columns {
        display: flex;
        flex-direction: row;
    }

    &--column-item {
        width: calc(50% - 12px);

        &:first-child {
            margin-right: auto;
        }

        &:last-child {
            margin-left: auto;
        }
    }

    &--column-item-cc-phone {
        &:first-child {
            width: 130px;
        }

        &:last-child {
            width: calc(100% - 154px);
        }
    }
}

@media screen and (max-width: 1400px) {
    .edit-client-account-popup {
        .MuiDialogActions-root {
            padding-right: 24px;
        }

        &--form {
            width: 100%;
        }

        .popup-header {
            width: 100%;
        }

        &--icon-image {
            display: none;
        }
    }
}

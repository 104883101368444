@import "src/styles/variables";

.sidebar-description {
    color: $sidebarDescriptionColor;
    background-color: $sidebarBackgroundLighter;
    font-family: Lato, sans-serif;
    font-size: 14px;
    letter-spacing: 0.35px;
    padding: 24px 40px 0 40px;
    line-height: 1.22;

    &__add,
    &__edit {
        font-size: 14px;
        letter-spacing: 0.35px;
        font-weight: 400;
    }

    &__add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $sidebarActiveColor;
    }

    &__edit {
        color: $sidebarDescriptionColor;
    }

    &__edit-button {
        float: right;
    }
}

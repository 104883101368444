@import "src/styles/variables";

.tile-title {
    font-size: 24px;
    color: $defaultHighlightTextColor;
    height: 60px;
    line-height: 1.2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@import "src/styles/variables";

.collection-tile {
    padding: 0;
    height: #{$tileCollectionHeight};

    &__media {
        position: relative;
        height: 255px;
    }

    &__image-main {
        float: left;
        width: 254px;
        height: 255px;
    }

    &__image-small {
        float: right;
        width: calc(100% - 258px);
        height: 126px;

        &:nth-child(3) {
            margin-top: 3px;
            height: 127px;
        }
    }

    &__image-large {
        float: left;
        width: 100%;
        height: 255px;
    }

    &__details {
        display: flex;
        align-items: center;
        margin-top: 13px;
        color: rgba(105, 105, 105, 0.6);
        font-size: 11px;
        padding: 0 24px;
    }

    &__date,
    &__user {
        color: $tileDefaultTextColor;
        padding: 0 4px;
    }

    &__title {
        margin: 25px 0 10px 0;
        padding: 0 24px;
    }

    &__footer {
        float: right;
        display: flex;
        margin-top: 17px;
        padding-right: 24px;
        grid-gap: 12px;
    }

    &__share {
        float: left;
        display: flex;
        margin-top: 17px;
        padding-left: 24px;
    }

    &__locked-icon {
        width: 32px;
        height: 32px;
        border-radius: 3px;
        margin-right: 8px;
        position: relative;
        top: 5px;

        svg {
            position: relative;
            left: 2px;
            width: 28px;
            height: 28px;
        }
    }
}

.wed-media-gallery-image-annotation-popup {
    .popup-content {
        padding: 12px 20px;
    }

    .popup-header {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 20px;
    }

    .popup-action {
        margin-top: 0;
        margin-bottom: 0;
    }

    .MuiDialog-container {
        cursor: default;
    }

    .MuiDialogActions-root {
        padding-bottom: 0;
    }
}

@import "src/styles/variables";

.label-title-wrapper {
    &__label {
        font-size: 12px;
        color: $tileDefaultTextColor;
        margin-bottom: 4px;
    }

    .tile-title {
        height: auto;
        max-height: 60px;
    }
}

@import "src/styles/variables";

.sidebar-title {
    height: 104px;
    overflow: hidden;
    color: $sidebarHeaderColor;
    background-color: $sidebarBackgroundLighter;
    font-size: 22px;
    font-weight: 500;
    padding: 10px 40px 0 40px;
    margin: 0;
    display: flex;
    align-items: center;

    &--wrapper {
    }

    &--container {
        max-height: 64px;
        display: flex;
        align-items: center;
    }

    &--content {
        display: -webkit-box;
        overflow: hidden;
        max-height: 64px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    small {
        display: block;
        white-space: nowrap;
        color: $sidebarValueLabelColor;
        font-weight: 300;
        font-size: 16px;
    }

    &--with-icon {
        padding-left: 17px;

        .wed-icon {
            display: flex;
            margin: 0 12px 0 0;
            min-width: 42px;
            width: 42px;
            height: 42px;

            svg,
            path {
                fill: $sidebarHeaderColor;
            }
        }
    }
}

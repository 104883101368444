@import "../../../styles/variables";

.page-info-box {
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    margin: 5px 0;
    color: $white;
    min-height: 39px;
    letter-spacing: normal;

    small {
        display: block;
        opacity: 0.4;
        line-height: 5px;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.3px;
    }
}

@media screen and (max-width: 1700px) {
    .page-info-box {
        max-width: 350px;
        overflow: hidden;
        white-space: nowrap;
        min-height: 43px;
    }
}

@import "src/styles/variables";

.wed-menu-buttons {
    height: 48px;
    margin-right: 26px;

    button {
        margin-left: 24px;
        color: #{$wedMainMenuButtonColor};
        svg path {
            fill: #{$wedMainMenuButtonColor};
        }

        &:disabled {
            color: #{$wedMainMenuButtonDisabledColor};
            svg path {
                fill: #{$wedMainMenuButtonDisabledColor};
            }
        }

        &:hover {
            color: #{$wedMainMenuButtonHoverColor};
            svg path {
                fill: #{$wedMainMenuButtonHoverColor};
            }
        }
    }

    @media screen and (max-width: 1980px) {
        button {
            margin-left: 12px;
        }
    }
}

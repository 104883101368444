@import "src/styles/variables";

.user-my-profile-popup {
    &--row {
        display: flex;
    }

    &--col50 {
        width: calc(50% - 12px);
    }

    &--col50:last-child {
        margin-left: auto;
    }

    &--content-user-info-row {
        display: flex;
        margin: 12px 0;
    }

    &--content-user-info-row-label {
        color: $defaultFormLabelColor;
        width: 140px;
    }

    &--content-user-info-row-label-value {
        font-weight: 500;
    }
}

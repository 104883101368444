@import "src/styles/variables";

.directory-tile {
    height: #{$tileDirectoryHeight};

    &__media {
        position: relative;
        height: 105px;
    }

    &__image {
        width: 90px;
        height: 100px;
        border-radius: 3px;
        margin-right: 8px;
        display: inline-block;
        overflow: hidden;

        .wed-image {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__more-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 26px;
        left: 278px;
        width: 48px;
        height: 48px;
        opacity: 0.75;
        border-radius: 3px;
        background-color: $defaultHighlightTextColor;
        color: $defaultBackground;
        font-size: 14px;
        font-weight: 800;
    }

    &__title {
        margin: 16px 0 17px 0;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

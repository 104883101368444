@import "src/styles/variables";

.user-card {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--initials {
        height: 48px;
        overflow: hidden;
        margin: 0 22px 0 35px;
        padding: 0;
        display: flex;
        align-items: center;

        .initials-internal-undefined {
            background-color: $userCardInitialsBackground;
            opacity: 1;
        }
    }

    &--content {
        display: flex;
        justify-content: left;
        height: 100%;
        overflow: hidden;
        flex-direction: column;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    &--header {
        color: $userCardNameColor;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.35px;
        margin: 0;
        padding: 0;
    }

    &--email {
        color: $userCardEmailColor;
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 0.25px;
        margin: 0;
        padding: 0;
    }
}

@import "src/styles/variables";

.wed-media-gallery-version {
    height: 100%;
    line-height: 0;

    &__body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__header {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $defaultTextColor;
        text-align: center;
        font-size: 16px;
    }

    &__container {
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__content {
        width: 100%;
        height: calc(100% - 80px);
    }

    &__loading {
        img {
            opacity: 0.01 !important;
            width: 1px !important;
            height: 1px !important;
        }

        .wed-media-gallery-image__icon {
            visibility: hidden;
        }
    }

    ul {
        width: calc(100% - 10px);
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        margin-top: 8px;

        &:first-child {
            margin-top: 0;
        }
    }

    .wed-media-gallery-image {
        width: 100%;

        .wed-media-gallery-image__img {
            width: 100%;
            border-radius: $tileBorderRadius;
            border-color: transparent;
            border-style: solid;
            border-width: 1px;
        }

        .wed-media-gallery-image__icon {
            width: 100%;
            height: 150px;
            border-radius: $tileBorderRadius;
            border-color: transparent;
            border-style: solid;
            border-width: 1px;

            .wed-icon {
                width: 100px;
                height: 100px;
            }
        }

        .wed-media-gallery-image__blank {
            border-radius: $tileBorderRadius;
            background-color: #000000;
            opacity: $defaultImageBoxBlankOpacity;
        }

        .wed-icon {
            opacity: $defaultImageContentOpacity;
        }
    }

    .wed-media-gallery-image-active,
    .wed-media-gallery-image__clickable:hover {
        .wed-media-gallery-image__img {
            border-color: $defaultBorderColor;
        }

        .wed-media-gallery-image__icon {
            border-color: $defaultBorderColor;
        }

        .wed-media-gallery-image__blank {
            opacity: 0;
        }

        .wed-icon {
            opacity: 1;
        }
    }
}

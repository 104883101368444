@import "src/styles/variables";

.comment-thread {
    .comment-note-with-items {
        .comment-note--initials:after {
            display: flex;
        }

        .comment-note--container-line-wrapper-bottom {
            .comment-note--container-line {
                display: flex;
            }
        }
    }

    &--items {
        .comment-note {
            margin-top: 0;
            .comment-note--initials:after {
                display: flex;
                height: calc(100% - 32px);
            }

            .comment-note--container-line-wrapper-top {
                .comment-note--container-line {
                    display: flex;
                }
            }

            .comment-note--container-line-wrapper-bottom {
                .comment-note--container-line {
                    display: flex;
                }
            }
        }

        .comment-note:last-child {
            .comment-note--initials:after {
                display: none;
            }

            .comment-note--container-line-wrapper-bottom {
                .comment-note--container-line {
                    display: none;
                }
            }
        }
    }

    .comment-note--content-actions {
        visibility: hidden;
    }
}

.comment-thread:hover {
    .comment-note--content-actions {
        visibility: visible;
    }
}

.sidebar {
    width: 100%;
    height: 100%;

    &--container {
        height: 100%;
    }

    &__loader {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

@import "src/styles/variables";

.admin-panel-nav {
    margin: 0;
    padding: 200px 0 0 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    background-color: $headerBackgroundColor;

    &__item {
        margin: 0;
        padding: 0;
        font-size: 16px;
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 8px 16px;

        &.active,
        &:hover {
            background-color: $hoverActiveColor;
        }
    }

    &__icon {
        margin-right: 16px;
    }

    &__label {
        color: $white;
        text-decoration: none;
    }
}

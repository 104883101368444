@import "src/styles/variables";

.client-tile {
    display: flex;
    flex-direction: column;
    height: #{$tileClientHeight};

    &__stats {
        display: flex;
    }

    &__title {
        margin-top: 25px;
    }

    &__details {
        display: flex;
        align-items: center;
        margin-top: 20px;
        color: $tileTextDarkColor;
        font-size: 11px;
    }

    &__date,
    &__user {
        color: $tileDefaultTextColor;
        padding: 0 4px;
    }

    &__icons {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;
        margin-left: auto;
        grid-gap: 12px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
    }
}

@import "../../../../styles/variables";

.breadcrumb-item {
    cursor: pointer;
    position: relative;
    letter-spacing: 0.6px;
    text-decoration: none;

    &--open {
        background-color: $hoverActiveColor;
    }

    &__menu {
        display: none;
        position: absolute;
        top: 32px;
        right: 0;
        z-index: 1;

        &--active {
            display: block;
        }
    }
}

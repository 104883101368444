@import "src/styles/variables";

.sidebar-approval {
    padding-top: 24px;

    &__title {
        padding: 0 40px;
        display: flex;
        align-items: center;
        color: $sidebarActiveColor;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0;
        cursor: pointer;

        .wed-icon {
            transition: transform 0.3s;
        }

        &--hide {
            .wed-icon {
                transform: rotate(-90deg);
            }
        }
    }

    &__container {
        margin-top: 8px;
    }

    &__item {
        padding: 5px 48px;
        position: relative;
        display: flex;

        &:nth-child(odd) {
            background: linear-gradient(
                90deg,
                rgba($sidebarBackgroundDarker, 0) 0%,
                rgba($sidebarBackgroundDarker, 0.8) 20%,
                rgba($sidebarBackgroundDarker, 0.8) 80%,
                rgba($sidebarBackgroundDarker, 0) 100%
            );
        }
    }

    .item-dot-status {
        position: relative;
        top: 6px;
    }

    &__item-date,
    &__item-username {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.35px;
        color: $sidebarActiveColor;
        font-weight: 500;
    }

    &__item-by {
        font-size: 12px;
        color: $sidebarDefaultColor;
        position: relative;
        top: 3px;
        justify-content: center;
        text-align: center;
        margin-left: 4px;
        margin-right: 4px;
    }

    &__item-username {
        align-self: start;
    }
}

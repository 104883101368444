@import "src/styles/variables";

.sidebar-sub-content {
    background-color: $sidebarBackgroundLighter;
    padding: 24px 40px 0 40px;

    &__title {
        display: flex;
        align-items: center;
        color: $sidebarActiveColor;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0;

        .sidebar-sub-content--arrow {
            transition: transform 0.3s;

            svg,
            path {
                fill: $sidebarActiveColor;
            }
        }
    }

    &--with-collapse {
        cursor: pointer;
    }

    &--hide {
        .sidebar-sub-content--arrow {
            transform: rotate(-90deg);
        }
    }

    &--right-icon-button {
        margin-left: auto;
    }
}

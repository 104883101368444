@import "src/styles/variables";

.terms-and-conditions {
    height: calc(100% - 185px);
    min-height: 500px;

    &--container {
        background: $defaultBackground;
        height: 100%;
    }

    &--container-body {
        padding-right: 20px;
    }
}

.terms-and-conditions-popup {
    .MuiDialogActions-root {
        margin-right: 0;
    }
}

@import "src/styles/variables";

.add-file-to-data {
    height: calc(100% - 185px);
    min-height: 500px;

    &--container {
        background: $defaultBackground;
        height: 100%;
    }

    &--container-body {
        padding-right: 20px;
    }
}

.user-edit-team-members-popup {
    .team-wed-table {
        min-height: 160px;
        margin-top: 15px;

        .user-card--initials {
            margin-left: 15px;
        }
    }

    .team-wed-table--cell-action {
        width: 50px;

        button {
            border: 0;
            background-color: transparent;
            margin-top: -10px;
        }
    }

    &--content {
    }

    &--user-members {
    }
}

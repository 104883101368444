@import "src/styles/variables";

.wed-media-gallery-image-annotation {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--hidden {
        display: none;
    }

    &--image-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    #wed-media-image-annotation-container--rect {
        position: absolute;
        width: 100%;
        height: 100%;

        rect {
            fill: white;
            stroke: red;
            stroke-width: 1;
            stroke-dasharray: 5, 5;
            stroke-opacity: 0.9;
            fill-opacity: 0.2;
        }
    }
}

.wed-media-gallery-image-annotation--draw-mode {
    cursor: not-allowed;

    #wed-media-image-annotation-container--rect {
        cursor: crosshair !important;
    }

    .wed-media-gallery-image-annotation--image-container {
        cursor: crosshair !important;
    }
}

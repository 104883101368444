.wed-inside-form-element {
    .MuiInputBase-root {
        .MuiSelect-select {
            height: unset;
            min-height: unset;
            min-width: 115px;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.3px;
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .MuiInput-underline:before {
        display: none;
    }

    .MuiInput-underline:after {
        display: none;
    }
}

@import "src/styles/variables";

.item-dot-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    visibility: hidden;

    &--color-requested {
        visibility: visible;
        background-color: $statusDotColorRequested;
    }

    &--color-in_progress {
        visibility: visible;
        background-color: $statusDotColorInProgress;
    }

    &--color-approved {
        visibility: visible;
        background-color: $statusDotColorApproved;
    }

    &--color-rejected {
        visibility: visible;
        background-color: $statusDotColorRejected;
    }
}

@import "src/styles/variables";

.user-team-user-profile-page {
    &--container {
        margin: 0 65px 33px 65px;
    }

    &--file-list-container {
        padding-bottom: 20px;
    }
}

@import "src/styles/variables";

.profile-with-projects {
    display: flex;
    flex-direction: row;

    &--profile {
        margin-top: 91px;
        width: 450px;

        .tile {
            background-color: transparent;
            border-color: transparent;
        }
    }

    &--projects {
        margin-top: 31px;
        width: calc(100% - 474px);
        margin-left: auto;

        .container-grid__tiles {
            margin-top: 0;
            margin-bottom: 0;
        }

        .main-menu {
            padding-left: 0;
            justify-content: center;
            display: flex;
        }

        .main-menu a {
            border-bottom-color: transparent;
        }

        .main-menu a span {
            width: 145px !important;
        }

        .main-menu .main-menu__active a {
            border-bottom-color: $markCommonColor;
        }
    }

    &--projects-menu {
        height: 60px;
    }
}

@media screen and (max-width: 1400px) {
    .profile-with-projects {
        flex-direction: column;

        &--profile {
            width: auto;
        }

        &--projects {
            width: auto;
            margin-left: unset;
        }
    }
}

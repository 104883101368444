@import "src/styles/variables";

.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    opacity: 0.4;
    border-radius: 3px;
    background-color: $defaultHighlightTextColor;
    color: $defaultBackground;
    font-size: 14px;
    font-weight: 700;

    &.initials-size-large {
        width: 39px;
        height: 39px;
    }

    &.initials-external-user {
        background-color: $initialsExternalUserColor;
        opacity: 1;
    }

    &.initials-internal-user {
        background-color: $initialsInternalUserColor;
        opacity: 1;
    }
}

@import "../../../styles/variables";

.header {
    .navbar {
        display: flex;
        align-items: center;

        &__icon {
            display: flex;
            align-items: center;
            margin-right: 16px;

            img {
                margin-right: 16px;
            }
        }

        &__title {
            font-size: 14px;
        }

        .project-control-icons {
            margin-left: auto;

            li {
                align-items: center;
                display: flex;
            }
        }

        .wed-menu-buttons {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
    }
}

.header--shared {
    .main-menu {
        padding-left: 0;

        li.header--shared-nav-menu-item-all-collection {
            a {
                span {
                    width: 120px;
                }
            }
        }

        li.header--shared-nav-menu-item-current-collection {
            a {
                span {
                    width: 150px;
                }
            }
        }

        li a.active {
            border-bottom: 2px solid $markCommonColor;

            span {
                color: #fff;
            }
        }
    }
}

.admin-panel-invite-new-users {
    &--new-user-container {
        .add-new-tag-row {
            min-height: 80px;
        }

        .tag-item-value {
            max-width: 400px;
        }
    }

    .tag-list {
        margin-top: 0;
    }

    .file-edit-tags-popup--add-new-tag-row {
        display: flex;
        align-items: center;

        .MuiFormControl-marginNormal {
            margin-top: 0;
            margin-bottom: 0;
        }

        .MuiFormControl-fullWidth {
            width: calc(100% - 160px);
        }

        .MuiButtonBase-root {
            margin-left: auto;
            min-height: 46px;
        }
    }

    .admin-panel-invite-new-users-without-margin {
        align-items: unset;
        min-height: auto;
        margin-bottom: 24px;
    }
}

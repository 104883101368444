.popup-header {
    font-weight: 300;
    font-size: 30px;
    color: #fff;
    margin-top: 4px;
    margin-bottom: 22px;

    &--center {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 35px;
    }
}

@import "src/styles/variables";

.client-tile__create-client {
    height: #{$tileClientHeight};
}

.client-list-page--table-cell-active-project-exists {
    color: $activeColor;
    margin-right: 6px;
}

.client-list-page--table-cell-active-project-not-exists {
    color: $tileDefaultTextColor;
    margin-right: 6px;
}

.client-list-page--table-cell-closed-project-exists {
    color: $tileDefaultTextColor;
    margin-left: 6px;
}

.client-list-page--table-cell-closed-project-not-exists {
    color: $tileDefaultTextColor;
    margin-left: 6px;
}

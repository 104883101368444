@import "src/styles/variables";

.sidebar-information-details {
    padding: 32px 40px 0 40px;
    background-color: $sidebarBackgroundLighter;

    &__row {
        display: grid;
        align-items: center;
        grid-template-columns: 100px auto;
    }

    &__label {
        font-size: 12px;
        color: $sidebarDarkLabelColor;
    }

    &__text {
        font-family: Lato, sans-serif;
        font-size: 14px;
        line-height: 1.77;
        letter-spacing: 0.3px;
        color: $sidebarValueLabelColor;
        font-weight: 500;

        .sidebar-information-details__label {
            margin: 0 8px;
        }
    }
}

@import "src/styles/variables";

.project-control-icons {
    ul {
        float: right;
        display: flex;
        margin: 0 65px 0 0;
        padding: 0;
        list-style: none;
    }

    li {
        height: 48px;
        margin: 0 0 0 16px;
        padding: 0;
        display: block;
    }

    button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }
    }

    li.project-control-icons__big-width-screen {
        display: none;
    }

    @media screen and (min-width: #{$sidebarMinWidth}) {
        li.project-control-icons__big-width-screen {
            display: inline-block;
        }
    }
}

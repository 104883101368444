@import "src/styles/variables";

.comment-form {
    display: flex;
    flex-direction: row;
    padding: 0 40px;

    &--fields {
        width: calc(100% - 45px);
    }

    &--action {
        width: 32px;
        text-align: center;
        padding-top: 16px;
        margin-left: auto;

        button {
            border: 0;
            background: transparent;
            cursor: pointer;
        }

        button:disabled {
            cursor: default;
        }
    }

    .MuiInputBase-input,
    .MuiInputBase-root .MuiOutlinedInput-input,
    .MuiOutlinedInput-multiline {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.33;
        letter-spacing: 0.3px;
        color: $commentsContentColor;
        padding: 10px;

        textarea {
            padding: 0 !important;
            width: 100%;
            height: 100%;
        }
    }

    label {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.33;
        letter-spacing: 0.3px;
    }
}

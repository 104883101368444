@import "src/styles/variables";

.wed-media-gallery-preview {
    width: 100%;
    height: 100%;

    &__loading {
        img {
            opacity: 0.01 !important;
        }

        .wed-media-gallery-image__icon {
            visibility: hidden;
        }

        .wed-media-gallery-preview-menu {
            display: none;
        }
    }

    &-container {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
    }

    .wed-media-gallery-image {
        width: calc(100% - 80px);
        height: 100%;

        .wed-media-gallery-image__img {
            width: 100%;
            height: 100%;
        }

        .wed-media-gallery-image__icon {
            width: 100%;
            height: 100%;

            .wed-icon-media-gallery-preview {
                width: 125px;
                height: 125px;
            }
        }
    }
}

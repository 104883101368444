@import "src/styles/variables";

.list-project-content {
    .project-tile__create-project {
        height: #{$tileProjectHeight};
    }

    .wed-table {
        .project__favorite {
            display: block;
            margin-top: -4px;
        }
    }
}

@import "src/styles/variables";

.share-layout-page {
    &__content {
        margin-top: 78px;
    }

    .sidebar-wrapper__sidebar {
        top: 78px;
        height: calc(100vh - 78px);
    }
}

@import "src/styles/variables";

.wed-media-gallery {
    &__box {
        overflow: hidden;
    }

    &__dashboard {
        display: flex;
        flex-direction: column;
    }

    &__main-content {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 315px);
    }

    &__version {
        width: 160px;
        height: 100%;
        overflow: hidden;
    }

    &__preview {
        margin-left: auto;
        display: flex;
        width: calc(100% - 174px);
        height: 100%;
        overflow: hidden;
    }

    &__strip {
        margin-top: 12px;
        height: 80px;
        margin-left: auto;
        width: calc(100% - 174px);
    }

    .wed-icon-button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    .wed-icon-button-prev {
        transform: rotate(90deg);
        width: 32px;
        height: 32px;
        margin: 4px;
        overflow: hidden;
    }

    .wed-icon-button-next {
        transform: rotate(270deg);
        width: 32px;
        height: 32px;
        margin: 4px;
        overflow: hidden;
    }

    .wed-icon-button-up {
        transform: rotate(180deg);
        width: 32px;
        height: 32px;
        margin: 4px auto;
        overflow: hidden;
    }

    .wed-icon-button-down {
        transform: rotate(0deg);
        width: 32px;
        height: 32px;
        margin: 4px auto;
        overflow: hidden;
    }

    .wed-icon-button:disabled {
        cursor: default;
    }

    @media screen and (max-width: 1280px) {
        &__strip {
            display: none;
        }

        &__version {
            display: none;
        }

        &__preview {
            width: 100%;
            height: calc(100vh - 200px);
        }
    }
}

@import "src/styles/variables";

.sidebar-summary-info {
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    background-color: $sidebarBackgroundDarker;

    &__icon {
        position: absolute;
        left: 48px;
    }

    &__text {
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.18px;
        color: $sidebarActiveColor;
    }

    &__with-status-icon {
        .sidebar-summary-info__icon {
            left: 74px;
        }

        .sidebar-summary-info__status-icon {
            width: 42px;
            height: 42px;
            display: block;
            position: absolute;
            left: 10px;
        }

        .collection_share-icon {
            margin-top: 0;
        }
    }
}

@import "src/styles/variables";

.comments-and-proofing-tile {
    display: flex;
    grid-gap: 24px;

    &__image {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;

        .wed-image {
            align-content: center;
            max-width: 200px;
            max-height: 125px;
            width: 100%;
            height: 100%;
            display: inline-block;
        }
    }

    &__image-icon-container {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;

        .wed-icon-size-normal {
            align-content: center;
            width: 75px;
            height: 75px;

            svg,
            path {
                fill: $defaultHighlightTextColor;
            }
        }
    }

    &__title {
        width: 350px;
        min-width: 350px;
        max-width: 350px;
        height: 125px;
        overflow: hidden;
        text-overflow: ellipsis;

        .tile-title {
            font-size: 14px;
        }

        .label-title-wrapper {
            margin-top: 24px;
        }

        .label-title-wrapper:first-child {
            margin-top: 0;
        }
    }

    &__description-approved-by {
        color: $tileDefaultTextColor;

        span {
            color: $tileHighlightTextColor;
        }
    }

    &__description-approved-by-name {
        margin-top: 24px;
        color: $tileDefaultTextColor;

        span {
            color: $tileHighlightTextColor;
        }
    }

    &__created-by {
        width: 30px;
    }

    &__description {
        width: 500px;

        &-texts {
            color: $tileDefaultTextColor;
            height: 122px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }

    &__actions {
        margin-left: auto;
        width: 350px;
        height: 125px;
        display: flex;
        flex-direction: column;
    }

    &__actions-date {
        color: $tileDefaultTextColor;
        text-align: right;
    }

    &__actions-link {
        text-align: right;
        position: relative;
        margin-top: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
            text-decoration: none;
            color: $tileDefaultTextColor;
        }

        a:hover {
            text-decoration: none;
            color: $iconControlHoverColor;
        }
    }
}

@media screen and (max-width: 1800px) {
    .comments-and-proofing-tile {
        &__title {
            width: 250px;
            min-width: 250px;
            max-width: 250px;
        }
    }
}

@media screen and (max-width: 1400px) {
    .comments-and-proofing-tile {
        &__description {
            display: none;
        }
    }
}

@media screen and (max-width: 1400px) {
    .comments-and-proofing-tile {
        &__title {
            display: none;
        }
        &__created-by {
            display: none;
        }
    }
}

@media screen and (max-width: 700px) {
    .comments-and-proofing-tile {
        &__actions {
            display: none;
        }
    }
}

@import "src/styles/variables";

.summary-page-proofing {
    &__container {
        display: flex;
        grid-gap: 24px;
    }

    &__item {
        .wed-image {
            display: block;
            width: 90px;
            height: 100px;
        }

        .item-dot-status {
            margin-top: 10px;
        }
    }
}

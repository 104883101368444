@import "src/styles/variables";

.file-tile {
    padding: 0;
    height: #{$tileFileHeight};

    &__media-preview {
        height: 253px;
        border-top-left-radius: $tileBorderRadius;
        border-top-right-radius: $tileBorderRadius;
        overflow: hidden;
    }

    &__media-preview-icon {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .wed-icon-size-normal {
            width: 165px;
            height: 165px;
        }

        image {
            width: 100%;
            height: 100%;
        }
    }

    &__media-preview-image {
        height: 100%;
        width: 100%;
        display: block;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 257px);
        padding: 20px 24px 20px 24px;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__dot-status {
        display: flex;
        margin-left: 15px;
    }

    &__meta {
        margin-left: auto;
        font-weight: 300;
        line-height: 1.4;
        color: $tileDefaultTextColor;
        text-align: right;

        &--top {
            font-size: 12px;
            letter-spacing: 0.3px;
        }

        &--bottom {
            font-size: 10px;
            letter-spacing: 0.25px;
        }
    }

    &__icon {
        position: relative;
        top: 3px;
        right: -5px;

        svg {
            font-size: 42px;
            fill: rgba(255, 255, 255, 0.5);
        }
    }
}

@import "src/styles/variables";

.user-team-list-page {
    &--container {
        margin: $defaultContentContainerMarginWithoutSidebar;
    }

    &--wed-table-name-cell-initials {
        width: 48px;
    }

    .wed-table {
        .user-team-list-page--table-cell-left {
            padding-left: 12px;
        }
    }
}

.project-files-media-gallery-page {
    .wed-media-gallery {
        margin-top: 23px;
    }

    .sidebar-wrapper__body {
        margin-bottom: 10px;
    }
    .sidebar-wrapper__page-content {
        margin-bottom: 0;
    }
}

@import "src/styles/variables";

.wed-image {
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;

    &--overlay {
        width: 100%;
        height: 100%;
        background: #000;
        opacity: $defaultImageBoxBlankOpacity;
        display: block;
    }

    &--radius-top-left {
        border-top-left-radius: $tileBorderRadius;
    }

    &--radius-top-right {
        border-top-right-radius: $tileBorderRadius;
    }

    &--radius-bottom-left {
        border-bottom-left-radius: $tileBorderRadius;
    }

    &--radius-bottom-right {
        border-bottom-right-radius: $tileBorderRadius;
    }
}

.popup-content {
    padding: 20px 80px;

    &--margin-small {
        padding: 20px 35px;
    }

    &--with-background {
        height: calc(100vh - 100px);
        padding-left: 0;
        padding-right: 0;

        .popup-header,
        .popup-action {
            padding-left: 35px;
            padding-right: 35px;
        }

        .popup-content-margin {
            padding-left: 35px;
            padding-right: 15px;
        }
    }
}

.status-action-button {
    position: relative;
    display: inline-block;
    transition: opacity 0.3s;

    &__menu {
        position: absolute;
        z-index: 5;
        top: 34px;
        right: 0;
        display: none;

        &--active {
            display: block;
        }

        &--move-to-left {
            left: 0;
            right: auto;
        }
    }
}

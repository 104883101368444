.admin-panel-action-button {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;

    .MuiButton-root {
        margin-left: auto;
    }

    &--extra-action {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

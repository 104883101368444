@import "src/styles/variables";

.tile {
    user-select: none;
    cursor: pointer;
    border-radius: $tileBorderRadius;
    background-color: $tileBackgroundColor;
    border: solid 1px $tileBackgroundColor;
    transition: background-color 0.3s;
    padding: 24px;
    position: relative;

    &--content-overlay {
        opacity: $defaultImageContentOpacity;
    }

    &:hover,
    &--active {
        background-color: $hoverActiveColor;
        box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.64);
        border: solid 1px rgba(176, 184, 200, 0.22);

        .wed-image {
            .wed-image--overlay {
                display: none;
            }
        }

        .tile--content-overlay {
            opacity: 1;
        }
    }

    &--disable-hover {
        pointer-events: none;
    }

    .status-action-button {
        opacity: 0;
        position: absolute;
        z-index: 1;
        right: 11px;
        top: 8px;

        &__menu {
            top: 48px;
            padding-top: 2px;
        }
    }

    .status-action-button__active {
        opacity: 1;
        z-index: 2;
    }

    &:hover {
        .status-action-button {
            opacity: 1;
        }

        .wed-image {
            .wed-image--overlay {
                display: none;
            }
        }

        .tile--content-overlay {
            opacity: 1;
        }
    }
}

@import "src/styles/variables";

.sidebar-no-selected {
    margin: 104px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__icon {
        margin: 24px 0;

        .wed-icon {
            height: 70px;
            width: 80px;

            svg,
            path {
                fill: $sidebarDarkIcon;
            }
        }
    }

    &__title {
        margin: 8px 0;
        font-size: 22px;
        font-weight: 500;
        color: $sidebarActiveColor;
    }

    &__description {
        line-height: 2;
        letter-spacing: 0.3px;
        font-size: 12px;
        color: $sidebarDarkText;
    }
}

@import "src/styles/variables";

.filter-form-box-search-text {
    .MuiFormControl-root {
        position: absolute;
        margin-left: -203px;
    }

    .MuiTextField-root .MuiInputBase-root {
        height: 32px;
        min-height: unset;
        max-height: unset;
        width: 200px;
        background-color: $defaultBackground;

        input {
            color: $filterFormColor;
        }
    }

    .filter-form-box__search-icon {
        cursor: pointer;
    }
}

.main-menu {
    .client-menu {
        &__projects {
            a {
                span {
                    width: 145px;
                }
            }
        }
    }
}

@import "src/styles/variables";

.project-tile .project__favorite {
    width: 48px;
    height: 48px;
    border-radius: $tileBorderRadius;
    padding: 3px;
}

.project-tile:hover .project__favorite.project__favorite:hover,
.project__favorite:hover {
    opacity: 0.6;
}

.project-tile:hover .project__favorite.project__favorite--loading:hover,
.project__favorite--loading:hover {
    opacity: 0.3;
}

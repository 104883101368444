@import "src/styles/variables";

.search-result {
    height: calc(100% - 185px);
    min-height: 500px;

    &--container {
        background: $defaultBackground;
        height: 100%;
    }

    &--container-body {
        padding-right: 20px;
    }

    &--container-no-results {
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
    }

    &--container-results {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        display: grid;
        grid-gap: 24px;
    }

    &--item {
        display: flex;
        grid-gap: 24px;
    }

    &--item-container:first-child {
        margin-top: 24px;
    }

    &--item-container:last-child {
        margin-bottom: 24px;
    }

    &--item-image {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;

        .wed-image {
            align-content: center;
            max-width: 200px;
            max-height: 125px;
            width: 100%;
            height: 100%;
            display: inline-block;
        }
    }

    &--image-icon-container {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;

        .wed-icon-size-normal {
            align-content: center;
            width: 75px;
            height: 75px;

            svg,
            path {
                fill: $defaultHighlightTextColor;
            }
        }
    }

    &--title {
        width: 350px;
        min-width: 350px;
        max-width: 350px;
        height: 125px;
        overflow: hidden;
        text-overflow: ellipsis;

        .tile-title {
            font-size: 14px;
        }

        .label-title-wrapper {
            margin-top: 24px;
        }

        .label-title-wrapper:first-child {
            margin-top: 0;
        }
    }

    &--description {
        width: 500px;

        &-texts {
            color: $tileDefaultTextColor;
            height: 122px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }

    &--actions {
        margin-left: auto;
        width: 350px;
        height: 125px;
        display: flex;
        flex-direction: column;
    }

    &--actions-date {
        color: $tileDefaultTextColor;
        text-align: right;
    }

    &--actions-link {
        text-align: right;
        position: relative;
        margin-top: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
            text-decoration: none;
            color: $tileDefaultTextColor;
        }

        a:hover {
            text-decoration: none;
            color: $iconControlHoverColor;
        }
    }
}

@import "src/styles/variables";

.signup-invitation-page {
    &--email-row {
        font-size: 1rem;
    }

    &--email-row-label {
        color: $defaultTextColor;
    }

    &--email-row-email {
        margin-left: 24px;
    }

    &--input-tc {
        text-align: right;
    }
}

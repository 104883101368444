@import "src/styles/variables";

.admin-panel-container-text-table {
    font-size: 16px;
    font-weight: 500;
    color: $adminPanelActiveColor;

    table {
        margin: 0 auto;
    }

    table tr td {
        padding-top: 6px;
        padding-right: 48px;
    }

    table tr td:last-child {
        padding-right: 0;
    }

    label {
        color: $adminPanelDefaultColor;
    }
}

@import "src/styles/variables";

.count-icon {
    position: relative;
    margin-left: 16px;

    svg {
        position: relative;
        font-size: 42px;
        top: 6px;
        left: -11px;
        fill: rgba(255, 255, 255, 0.5);
        font-weight: 600;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 13px;
        right: 0;
        width: 25px;
        height: 25px;
        background-color: $defaultBackground;
        color: $defaultHighlightTextColor;
        border-radius: 50%;
        padding: 6px;
        font-size: 12px;
        font-weight: 600;
    }
}
